<template>
  <div class="modal-overlay" v-if="isVisible">
    <div class="modal modal-popup show column flex">
      <h3 style="margin-bottom: 1em">Edit Team Details</h3>
      <div class="flex row modal-links" style="margin-bottom: 1.5em">
        <div class="flex w-100">
          <div class="banner-container profile-banner">
            <img :src="bannerPreview || (localEditableTeam.banner ? localEditableTeam.banner : defaultProfile.banner)"
              alt="Banner" />
            <div class="edit-overlay" @click="triggerFileInput('banner')">
              <a class="edit-button">
                <img src="@/assets/svg/edit.svg" />
              </a>
            </div>
          </div>
          <input style="display: none;" id="team-banner" ref="bannerInput" type="file"
            @change="handleFileUpload('banner')" />
        </div>
      </div>
      <div class="flex row modal-links">
        <div class="flex">
          <div class="avatar-container profile-avatar">
            <img :src="avatarPreview || (localEditableTeam.avatar ? localEditableTeam.avatar : defaultProfile.avatar)"
              alt="Avatar" />
            <div class="edit-overlay" @click="triggerFileInput('avatar')">
              <a class="edit-button">
                <img src="@/assets/svg/edit.svg" />
              </a>
            </div>
          </div>
          <input style="display: none;" id="team-avatar" ref="avatarInput" type="file"
            @change="handleFileUpload('avatar')" />
        </div>
        <div class="modal-content-fill" style="margin-left: 2em">
          <div>
            <h2 for="team-name">Team Name</h2>
          </div>
          <div>
            <input class="link-item" id="team-name" v-model="localEditableTeam.name" type="text"
              placeholder="Enter team name" />
          </div>
        </div>
      </div>
      <div class="flex column modal-links">
        <div>
          <h2 class="link-title-marg" for="team-links">Team Links</h2>
        </div>
        <div class="flex link-item" v-for="(link, index) in localEditableTeam.socials" :key="index">
          <input v-model="link.link" type="text" placeholder="Enter link" />
          <button class="reject-btn flex" @click="removeLink(index)">
            <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 63.09 63.09">
              <polygon class="reject-btn-svg"
                points="63.09 13.28 49.81 0 31.55 18.26 13.28 0 0 13.28 18.26 31.55 0 49.81 13.28 63.09 31.55 44.83 49.81 63.09 63.09 49.81 44.83 31.55 63.09 13.28" />
            </svg>
          </button>
        </div>
        <div class="flex center">
          <button class="link-add flex" @click="addLink"
            v-if="localEditableTeam.socials ? localEditableTeam.socials.length < 5 : 'Add Link'">+</button>
        </div>
      </div>
      <div class="flex center edit-controls">
        <button @click="saveEdits">Save</button>
        <button @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import api from "../api";

export default {
  props: {
    isVisible: Boolean,
    editableTeam: Object,
    defaultProfile: Object
  },
  setup(props, { emit }) {
    const localEditableTeam = ref({ ...props.editableTeam });
    const avatarPreview = ref(null); // For previewing the selected avatar
    const bannerPreview = ref(null); // For previewing the selected banner

    // Watch for changes in the editableTeam prop to update the localEditableTeam
    watch(() => props.editableTeam, (newVal) => {
      localEditableTeam.value = { ...newVal };
    });

    const triggerFileInput = (type) => {
      if (type === 'avatar') {
        document.getElementById('team-avatar').click();
      } else if (type === 'banner') {
        document.getElementById('team-banner').click();
      }
    };

    const handleFileUpload = async (type) => {
      const file = type === 'avatar' ? document.getElementById('team-avatar').files[0] : document.getElementById('team-banner').files[0];
      if (!file) return;

      // Create a preview URL for the selected image
      if (type === 'avatar') {
        avatarPreview.value = URL.createObjectURL(file);
      } else if (type === 'banner') {
        bannerPreview.value = URL.createObjectURL(file);
      }

      try {
        const formData = new FormData();
        formData.append("file", file);  // Use "file" as the key name
        formData.append("teamId", localEditableTeam.value._id);

        const response = type === 'avatar' ? await api.uploadTeamAvatar(formData) : await api.uploadTeamBanner(formData);
        if (response.data && response.data.url) {
          // Update the local state with the new avatar or banner URL
          if (type === 'avatar') {
            localEditableTeam.value.avatar = response.data.url;
            avatarPreview.value = response.data.url; // Update the preview with the new URL from the server
          } else if (type === 'banner') {
            localEditableTeam.value.banner = response.data.url;
            bannerPreview.value = response.data.url; // Update the preview with the new URL from the server
          }
        } else {
          console.error("No URL returned in response:", response.data);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };


    const addLink = () => {
      if (!localEditableTeam.value.socials) {
        localEditableTeam.value.socials = [];
      }
      if (localEditableTeam.value.socials.length < 5) {
        localEditableTeam.value.socials.push({ link: '' });
      }
    };

    const removeLink = (index) => {
      if (localEditableTeam.value.socials && localEditableTeam.value.socials.length > index) {
        localEditableTeam.value.socials.splice(index, 1);
      }
    };

    const saveEdits = () => {
      emit('save', localEditableTeam.value);
      emit('close');
      window.location.reload();
    };

    const closeModal = () => {
      emit('close');
    };

    return {
      localEditableTeam,
      avatarPreview,
      bannerPreview,
      triggerFileInput,
      handleFileUpload,
      saveEdits,
      closeModal,
      addLink,
      removeLink
    };
  }
};
</script>

<style scoped>
.profile-avatar {
  top: 0;
}

.modal {
  height: 60vh;
  width: 100vh;
  margin-left: 30vh;
  overflow: auto;
}
</style>
