// socket.js
import { io } from 'socket.io-client';

let socket = null;

const initializeSocket = (userId) => {
  if (!socket) {
    socket = io('https://api.playerverse.gg:5001', {
      withCredentials: true,
      autoConnect: false,  // Set to false so we can connect manually
      query: { userId },
    });

    socket.connect();

    // Join a room based on userId
    socket.on('connect', () => {
      socket.emit('registerPlayer', userId);
      console.log('Successful socket connect', socket.id);
    });

    // Handle socket connection errors
    socket.on('connect_error', (err) => {
      console.error('Connection error:', err);
    });
  }
  return socket;
};

const getSocket = () => socket;

export { initializeSocket, getSocket };