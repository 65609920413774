<template>
  <div>
    <div class="loadingDiv flex center h-70" v-if="loading">
      <div class="spinner"></div>
    </div>
    <div class="flex center h-70" v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="flex center h-70" v-if="!isSignedUp">
        <p>No User Account on MATCHUP.GG</p>
      </div>
      <div v-else>
        <div class="sticky-header">
          <div>
            <ul class="page-tab-menu flex">
              <li :class="{ 'tab-active': selectedTab === 'Overview' }" @click="selectTab('Overview')">Overview</li>
              <li :class="{ 'tab-active': selectedTab === 'Matches' }" @click="selectTab('Matches')">Matches</li>
              <li :class="{ 'tab-active': selectedTab === 'CS2' }" @click="selectTab('CS2')">CS2</li>
              <li :class="{ 'tab-active': selectedTab === 'PlayerVerse' }" @click="selectTab('PlayerVerse')">PlayerVerse
              </li>
            </ul>
          </div>
          <div class="border-bottom profile-detailed flex"
            :style="{ backgroundImage: `url(${player.background ? player.background : defaultProfile.background})` }">
            <div class="profile-avatar">
              <img :src="player.avatar ? player.avatar : defaultProfile.avatar" />
            </div>
            <div v-if="player.badges" class="flex profile-badge">
              <div class="sidebar-badge" v-for="badge in player.badges.slice(0, 1)" :key="badge.index">
                <img :src="badge.src" :alt="badge.desc" :title="badge.desc" />
              </div>
            </div>
            <ul class="flex column">
              <div class="flex column">
                <li class="heading-text">{{ player.displayName }}</li>
                <li class="subheading-text" v-if="team"><a :href="'/team/' + team._id">{{ team.name }}</a></li>
                <li class="subheading-text" v-else>No Team</li>
              </div>
            </ul>
            <div class="flex column profile-details">
              <div class="flex elo-container">
                <span class="display-elo-banner">
                  <p>PlayerVerse Rating</p> <span style="font-weight: 900;"
                    :style="{ color: formatedEloColor.player }">{{ formatedElo.player ? formatedElo.player : 0 }}</span>
                </span>
                <span class="display-elo-banner">
                  <p>CS2</p> <span style="font-weight: 900;" :style="{ color: formatedEloColor.cs2 }">{{ formatedElo.cs2
                    ? formatedElo.cs2 : 0 }}</span>
                </span>
                <span class="nav-gamecoins flex">
                  <img src="../assets/svg/gamecoins.svg" />
                  <p style="font-weight: 900; font-style: italic;">{{ player.gamecoins ? player.gamecoins : 0 }}</p>
                </span>
              </div>
              <div class="header-details ">
                <div class="table flex" v-if="player.playerverseStats">
                  <p class="heavy table-row flex">{{ 'K/D ' + kdRatio }}</p>
                  <p class="heavy table-row flex">{{ 'HS ' + headShotPercentage + '%' }}</p>
                  <p class="heavy table-row flex">{{ 'WR ' + winRate + '%' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="profile-stats flex column">
          <div class="page-tabs container">
            <div v-if="selectedTab === 'Overview'">
              <div class="flex match-timeline-container" v-if="matches">
                <h3>Matches</h3>
                <ul class="flex match-timeline">
                  <li class="match-timeline-item" v-for="match in matches.slice(-10).reverse()" :key="match.index">
                    <p><a :href="'/match/' + match._id">{{ match.finalStats ? match.finalStats.score : 'In Progress'
                        }}</a></p>
                    <span class="inprogress"></span>
                  </li>
                </ul>
              </div>
              <div class="flex profile">
                <div class="content-container">
                  <div v-if="player.playerverseStats">
                    <h3 class="mbottom5">Quicklook</h3>
                    <div class="flex column profile-stats-container">
                      <ul class="flex table column ladder-table">
                        <div class="table-headings flex">
                          <div>
                            <div class="table-heading">Statistic</div>
                          </div>
                          <div>
                            <div class="table-heading">Value</div>
                          </div>
                        </div>
                        <div class="table-content flex" v-for="(value, key) in player.playerverseStats" :key="key">
                          <div>{{ formatStatLabel(key) }}</div>
                          <div>{{ value }}</div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="content-sidebar">
                  <div v-if="player.badges" class="flex column">
                    <h3 class="mbottom5">Badges</h3>
                    <ul class="sidebar-badge-container flex mbottom10">
                      <li class="sidebar-badge" v-for="badge in player.badges.slice(0, 5)" :key="badge.index">
                        <img :src="badge.src" :alt="badge.desc" :title="badge.desc" />
                      </li>
                    </ul>
                  </div>
                  <div v-if="player.social || player.discordId || player.steamId">
                    <h3 class="mbottom5">Social</h3>
                    <ul class="sidebar-link-container mbottom10">
                      <a v-if="player.steamId" :href="'https://steamcommunity.com/profiles/' + player.steamId"
                        target="_blank">
                        <li class="sidebar-link">Steam</li>
                      </a>
                      <a v-if="player.discordId" :href="'https://discord.com/users/' + player.discordId"
                        target="_blank">
                        <li class="sidebar-link">Discord</li>
                      </a>
                      <a v-for="link in player.social" :key="link" :href="link.href">
                        <li class="sidebar-link">{{ link.title }}</li>
                      </a>
                    </ul>
                  </div>
                  <div v-if="player.tournoments">
                    <h3 class="mbottom5">Tournaments</h3>
                    <ul class="sidebar-link-container mbottom10">
                      <li class="sidebar-link" v-for="tourn in player.tournoments.slice(-10).reverse()" :key="tourn">
                        <a :href="tourn.href">{{ tourn.title }}</a>
                      </li>
                      <li>View all</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectedTab === 'Matches'">
              <p class="no-item-message">Coming Soon</p>
            </div>
            <div v-if="selectedTab === 'CS2'">
              <p class="no-item-message" v-if="csgoStats.message">{{ csgoStats.message }}</p>
              <ul class="flex table column ladder-table" v-else>
                <div class="table-headings flex">
                  <div>
                    <div class="table-heading">Statistic</div>
                  </div>
                  <div>
                    <div class="table-heading">Value</div>
                  </div>
                </div>
                <div class="table-content flex" v-for="(value, key) in cs2StatsSteam.stats" :key="key">
                  <div>{{ value.name }}</div>
                  <div>{{ value.value }}</div>
                </div>
                <div class="table-content flex" v-for="(value, key) in csgoStats" :key="key">
                  <div>{{ key }}</div>
                  <div>{{ value }}</div>
                </div>
              </ul>

            </div>
            <div v-if="selectedTab === 'PlayerVerse'">
              <p class="no-item-message">Coming Soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steamApi from "../api";

export default {
  name: "ProfilePage",
  data() {
    return {
      player: {},
      team: null,
      csgoStats: {},
      formatedElo: {},
      loading: false,
      error: null,
      formatedEloColor: {},
      selectedTab: "Overview",
      defaultProfile: { avatar: require('@/assets/svg/profiles/default/blankplayer.png'), background: require('@/assets/svg/profiles/default/background.svg') },
      isSignedUp: false,
      matches: {},
      cs2StatsSteam: {}
    };
  },
  computed: {
    // Calculate K/D ratio from playerverseStats
    kdRatio() {
      const kills = this.player.playerverseStats.totalKills || 0;
      const deaths = this.player.playerverseStats.totalDeaths || 1; // Avoid division by 0
      return (kills / deaths).toFixed(2);
    },

    // Calculate Headshot Percentage
    headShotPercentage() {
      const kills = this.player.playerverseStats.totalKills || 1; // Avoid division by 0
      const headshots = this.player.playerverseStats.totalHeadshots || 0;
      return ((headshots / kills) * 100).toFixed(2);
    },

    // Calculate Win Rate from matches
    winRate() {
      const totalMatches = this.matches.length || 1; // Avoid division by 0
      const totalWins = this.matches.filter(match => match.result === 'win').length;
      return ((totalWins / totalMatches) * 100).toFixed(2);
    }
  },
  methods: {
    formatStatLabel(key) {
      const labelMap = {
        total2Ks: "Total 2Ks",
        total3Ks: "Total 3Ks",
        total4Ks: "Total 4Ks",
        total5Ks: "Total 5Ks (Aces)",
        totalAssists: "Total Assists",
        totalDamage: "Total Damage",
        totalDeaths: "Total Deaths",
        totalKills: "Total Kills",
      };
      return labelMap[key] || key; // Default to key if no label found
    },
    async fetchData() {
      this.loading = true;
      const steamId = this.$route.params.steamid;

      try {
        // Check if the user is signed up
        const userCheck = await steamApi.checkIfUserExists(steamId);
        this.isSignedUp = userCheck.exists;

        if (!this.isSignedUp) {
          throw new Error('No User Account on PlayerVerse.gg');
        }

        // Fetch player data
        this.player = await steamApi.getPlayerSummaries(steamId);

        // Fetch team data if player has a team
        if (this.player.team) {
          this.team = await steamApi.getTeamProfile(this.player.team);
        }

        // Fetch match data for player
        this.matches = await steamApi.getMatches();

        if (this.player.gamecoins != null) {
          this.player.gamecoins = new Intl.NumberFormat().format(this.player.gamecoins);

        } else {
          this.player.gamecoins = "0";
        }

        if (this.player.elo != null) {
          this.formatedElo.player = new Intl.NumberFormat().format(this.player.elo);
          this.formatedEloColor.player = this.getColor(this.player.gamecoins);
        } else {
          this.formatedElo.player = "Unranked";
        }


        try {
          // Fetch CS:GO stats
          const statsResponse = await steamApi.getPlayerStats(steamId);
          const csgoStatsArray = await steamApi.getCsgoStats(steamId);

          if (csgoStatsArray != null) {
            const totalDamageDone = statsResponse.stats.find(stat => stat.name === 'total_damage_done')?.value || 0;
            const totalRoundsPlayed = statsResponse.stats.find(stat => stat.name === 'total_rounds_played')?.value || 1;
            const totalKills = statsResponse.stats.find(stat => stat.name === 'total_kills')?.value || 0;
            const totalMatchesPlayed = statsResponse.stats.find(stat => stat.name === 'total_matches_played')?.value || 1;
            const totalHeadshots = statsResponse.stats.find(stat => stat.name === 'total_kills_headshot')?.value || 0;
            const totalWins = statsResponse.stats.find(stat => stat.name === 'total_matches_won')?.value || 0;
            const totalDeaths = statsResponse.stats.find(stat => stat.name === 'total_deaths')?.value || 0;

            this.csgoStats = {
              avgDamagePerGame: (totalDamageDone / totalMatchesPlayed).toFixed(0),
              avgDamagePerRound: (totalDamageDone / totalRoundsPlayed).toFixed(0),
              avgKillsPerGame: (totalKills / totalMatchesPlayed).toFixed(0),
              avgKillsPerRound: (totalKills / totalRoundsPlayed).toFixed(2),
              hsPercent: ((totalHeadshots / totalKills) * 100).toFixed(0),
              winPercent: ((totalWins / totalMatchesPlayed) * 100).toFixed(0),
              kdRatio: (totalKills / totalDeaths).toFixed(2),
            };

            this.cs2StatsSteam = statsResponse;

            const csgoObject = csgoStatsArray;

            let rankId = (csgoObject[2] && csgoObject[2].rank_id) ?? 'Unranked';

            if (rankId === 'Unranked') {
              this.formatedElo.cs2 = 'Unranked';
            } else {
              this.formatedElo.cs2 = new Intl.NumberFormat().format(rankId);
              this.formatedEloColor.cs2 = this.getColor(rankId);
            }

            const weapons = [
              'glock', 'deagle', 'elite', 'fiveseven',
              'xm1014', 'mac10', 'ump45', 'awp', 'p90', 'ak47', 'aug', 'famas',
              'g3sg1', 'm249'
            ];

            this.weapons = weapons.map(weapon => {
              const shots = statsResponse.stats.find(stat => stat.name === `total_shots_${weapon}`)?.value || 1;
              const hits = statsResponse.stats.find(stat => stat.name === `total_hits_${weapon}`)?.value || 0;
              const accuracy = ((hits / shots) * 100).toFixed(0);
              return { name: weapon, accuracy };
            });

            this.sortedWeapons = this.weapons.sort((a, b) => b.accuracy - a.accuracy).slice(0, 4);
          }
        } catch (error) {
          console.error('Error fetching CS:GO stats:', error.message);
          // You can handle specific errors or display a message on the UI as needed.
          this.csgoStats.message = 'This user has not linked their CS2 account with PlayerVerse'
        }

      } catch (error) {
        console.error("Fetch error:", error);
        this.error = error.message || "An error occurred";
      } finally {
        this.loading = false;
      }
    },
    getColor(rankId) {
      if (rankId >= 0 && rankId <= 4999) {
        return "#bcc8da";
      } else if (rankId >= 5000 && rankId <= 9999) {
        return "#3a75c4";
      } else if (rankId >= 10000 && rankId <= 14999) {
        return "#1a4f8d";
      } else if (rankId >= 15000 && rankId <= 19999) {
        return "#6a33b9";
      } else if (rankId >= 20000 && rankId <= 24999) {
        return "#8a2be2";
      } else if (rankId >= 25000 && rankId <= 29999) {
        return "#d834eb";
      } else {
        return "#d834eb";
      }
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style scoped></style>
