<template>
    <div class="lp-site">
        <div class="lp-site-header sticky-header">
            <ul class="flex lp-site-menu">
                <li>
                    <img src="../assets/svg/logo_long.svg" alt="PlayerVerse.gg Logo" />
                </li>
                <li>
                    <button @click="login">Login via Steam</button>
                </li>
                <li>
                    <a href="https://discord.gg/pKkHSpUSMu" target="_blank"><button class="btn-discord">Our
                            Discord</button></a>
                </li>
            </ul>
        </div>
        <div class="lp-site-content lp-site-galaxy-bg flex">
            <div class="lp-site-galaxy-bg-overlay"></div>
            <div class="lp-hero-image-container flex row">
                <img src="../assets/svg/logo.svg" alt="PlayerVerse Logo" />
            </div>
            <div class="flex column">
                <h3>Where Grassroots Esports Comes to Life</h3>
                <p>At PlayerVerse.gg, we’re passionate about creating a space where competitive gaming thrives, starting
                    from the ground up. Our mission is simple: to foster a vibrant, grassroots Counter-Strike 2 (CS2)
                    community where players can test their skills, forge new teams, and rise through the ranks. Whether
                    you're an aspiring pro or a casual gamer who loves the rush of competition, PlayerVerse.gg is your
                    home for competitive gaming.</p>
                <p>This platform may be bare bones right now, but it’s built with love for the game and the community.
                    We’re here to grow alongside you. As we evolve, so will the tools and features to help you compete
                    at the highest level, all while maintaining the integrity of grassroots esports.</p>
            </div>
        </div>
        <div class="lp-site-content flex" style="background-color: #000;">
            <h3>Our Story: From Concept to Reality</h3>
            <span>
                <h4>Our Mission</h4>
                <p>
                    PlayerVerse.gg started with a vision: to provide players with a platform that wasn’t just another
                    big corporate hub, but a place for the everyday gamer. Think of us like your local rugby or
                    basketball
                    league—but for CS2. Our platform is a place where teams can form, rivalries can develop, and the
                    competitive scene can flourish.
                </p>
            </span>
            <span>
                <h4>Provide us your thoughts</h4>
                <p>With the help of community feedback and passion from players like you, PlayerVerse.gg is constantly
                    evolving. We’ve built it to focus on simplicity and usability. You won’t find any clutter—just the
                    core
                    essentials to get you playing and competing. As we grow, we’re committed to keeping PlayerVerse true
                    to
                    its grassroots origins.</p>
            </span>
        </div>
        <div class="lp-site-content-row flex column">
            <h3>Features that Keep the Competition Fierce</h3>
            <p style="text-align: center;margin-bottom: 5em;margin-top: -2em;">Our platform may be in its Alpha stage,
                but we’ve already put together some key features that bring
                the competitive CS2 experience to life.</p>
            <div class="flex row">
                <ul class="lp-features-menu">
                    <li>
                        <h4>Matchmaking System</h4>
                        <p>Tired of random queues? PlayerVerse.gg offers a structured matchmaking system where you can
                            create and join competitive matches. Whether it’s 1v1, team battles, or friendly scrims,
                            you’ll
                            find opportunities to test your skills and compete for bragging rights.</p>
                    </li>
                    <li>
                        <h4>Team Challenge Ladders & Individual Rankings</h4>
                        <p>We believe in long-term growth and progression. That’s why we offer team challenge ladders
                            and
                            individual ranking systems. Compete consistently, and see your rank climb as you sharpen
                            your
                            skills and prove your dominance in the CS2 scene.</p>
                    </li>
                    <li>
                        <h4>Wager Matches</h4>
                        <p>Feeling confident? Step into the ring with wager matches, where you and your opponent agree
                            on
                            the stakes before the match begins. This feature is for those who live for the thrill of
                            high-stakes gameplay.</p>
                    </li>
                </ul>
                <div class="lp-feature-img">
                    <img src="https://storage.googleapis.com/matchupgg-cdn-assets/site_examples.png" />
                </div>
            </div>
        </div>
        <div class="lp-site-content flex" style="background-color: #151515;">
            <div>
                <h3>Who built this?</h3>
                <p>PlayerVerse.gg is the brainchild of Samapa, a veteran in the Australian esports scene. With a history
                    of success in Counter-Strike, Halo, H1Z1, Call of Duty, and other competitive titles, Samapa led a
                    thriving esports organisation. After stepping away from the
                    competitive circuit, Samapa noticed a gap—there were fewer places for former players and friends to
                    compete and enjoy the thrill of grassroots competition. Driven by passion, Samapa set out to build
                    PlayerVerse.gg, a platform that brings that excitement back to life.
                </p>
                <img style="width: 50em" src="https://storage.googleapis.com/matchupgg-cdn-assets/WhoAmI.png" />
            </div>
            <div>
                <h3>Built by Gamers, For Gamers</h3>
                <p>PlayerVerse.gg is powered by gamers who understand what makes grassroots competition special. We know
                    that esports doesn’t always have to be about major leagues or corporate sponsors. It’s about you—the
                    community—and creating spaces where you can have fun, grow, and compete in the games you love.</p>
                <p>With each new feature and update, we’ll continue to focus on what matters most: your experience.
                    You’ll
                    have the power to shape the platform alongside us as we work together to make PlayerVerse.gg the
                    go-to
                    hub for competitive CS2 gaming.</p>
            </div>
        </div>
        <div class="lp-site-content flex">
            <h3>Join the Grassroots Revolution</h3>
            <span>
                <h4>We want your help</h4>
                <p>We’re still in the early stages, but with your feedback and support, PlayerVerse.gg will become more
                    than
                    just a platform—it’ll be a movement. A movement built by the community, for the community.</p>
            </span>
            <span>
                <h4>Enjoy the platform</h4>
                <p>Whether you’re here to rise through the rankings, challenge rivals, or just enjoy the thrill of
                    competition, PlayerVerse.gg is your home for grassroots esports. Let’s build something incredible
                    together.</p>
            </span>
        </div>
        <div class="lp-site-footer flex">
            <ul class="flex lp-footer-menu">
                <li>Created By <a href="https://github.com/Samapa" target="_blank">Samapa</a> || &copy; 2024
                    PlayerVerse.gg. All rights reserved.</li>
                <li><a href="https://discord.com/users/132932076344180736" target="_blank">Contact</a></li>
                <li><a href="https://discord.com/users/132932076344180736" target="_blank">Discord</a></li>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                <li><a href="/terms-of-service">Terms of Service</a></li>
            </ul>
        </div>
    </div>
</template>
<script>
import api from "@/api";

export default {
    name: 'RoadMap',
    setup() {
        const login = async () => {
            try {
                await api.initiateSteamLogin();
            } catch (error) {
                console.error("Error initiating Steam login:", error);
            }
        };

        return {
            login
        }
    },
}
</script>