<template>
  <div>
    <div v-if="loading" class="flex center h-70">
      <div class="spinner"></div>
    </div>
    <div v-if="!loading" class="flex column container">
      <div class="fill matchmaker flex column">
        <div class="flex mm-titleblock">
          <h3>Party</h3>
          <div class="joinQueueButton flex">
            <button v-if="isInParty" @click="leaveParty">Leave Party</button>
            <button @click="toggleQueue" :class="isInQueue ? 'searching' : ''">
              {{ isInQueue ? 'Leave Queue' : 'Join Queue' }}
            </button>
          </div>
        </div>
        <ul class="flex partyContainer">
          <li class="ProfileCard flex column">
            <div class="mm-avatar-container">
              <router-link :to="`/Profile/${user.id}`">
                <img :src="user._json.avatarfull" alt="avatar" class="mm-avatar" />
              </router-link>
            </div>
            <div class="mm-username">
              <p>{{ user.displayName }}</p>
            </div>
          </li>
          <li v-for="(teammate, index) in partyMembers" :key="index" class="teammateCard flex column">
            <div v-if="teammate" class="mm-avatar-container">
              <div class="avatar-container">
                <router-link class="flex center" :to="`/Profile/${teammate.id}`">
                  <img :src="teammate.avatar" alt="avatar" class="mm-avatar" />
                </router-link>
                <div v-if="user.id == teammate.isOwner">
                  <button class="remove-btn flex center" @click="removeMember(teammate.id)">X</button>
                </div>
              </div>
              <div class="mm-username">
                <p>{{ teammate.displayName }}</p>
              </div>
            </div>
            <div v-else class="teammateAdd flex">
              <button @click="openAddToPartyModal">+</button>
            </div>
          </li>
        </ul>
      </div>
      <AddToPartyModal v-if="showAddToPartyModal" :showModal="showAddToPartyModal" @close="closeAddToPartyModal" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { onMounted, onUnmounted, ref, computed } from 'vue';
import AddToPartyModal from "../components/AddToPartyModal.vue";
import api from "@/api";
import { getSocket } from '@/socket';

export default {
  name: 'MatchFinder',
  components: {
    AddToPartyModal,
  },
  setup() {
    const store = useStore();
    const socket = ref(null);
    const user = ref({});
    const loading = ref(true);
    const isInQueue = computed(() => store.getters.isInQueue);
    const showAddToPartyModal = ref(false);

    const partyMembers = computed(() => store.state.partyMembers);
    const isInParty = computed(() => partyMembers.value.some(member => member !== null));

    const joinQueue = async () => {
      try {
        socket.value.emit('joinQueue', { userId: user.value.id });
        await api.joinQueue();
        store.dispatch('updateQueueStatus', true); // Update Vuex state
      } catch (error) {
        console.error('Error joining queue:', error);
      }
    };

    const leaveQueue = async () => {
      try {
        socket.value.emit('leaveQueue', { userId: user.value.id });
        await api.leaveQueue();
        store.dispatch('updateQueueStatus', false); // Update Vuex state
      } catch (error) {
        console.error('Error leaving queue:', error);
      }
    };

    const toggleQueue = () => {
      if (isInQueue.value === true) {
        leaveQueue();
      } else {
        joinQueue();
      }
    };

    onMounted(async () => {
      try {
      const response = await api.checkLoginStatus();
      user.value = response.user;
      socket.value = getSocket();

      // WebSocket listener to sync queue status in real-time
      socket.value.on('joinQueue', () => store.dispatch('updateQueueStatus', true));
      socket.value.on('leaveQueue', () => store.dispatch('updateQueueStatus', false));

      socket.value.on('joinParty', () => {
        store.dispatch('checkIfInParty');
      });

      socket.value.on('leaveParty', () => {
        store.dispatch('checkIfInParty');
      });

      socket.value.on('acceptPartyInvitation', async () => {
        store.dispatch('checkIfInParty');
      });

      // Check queue status on page load/refresh
      await store.dispatch('checkQueueStatus');
      await store.dispatch('checkIfInParty');

      } catch (error) {
        console.error('Error during setup:', error);
      } finally {
        loading.value = false;
      }
    });

    onUnmounted(() => {
      if (socket.value) {
        socket.value.disconnect();
      }
    });

    const leaveParty = () => {
      store.dispatch('leaveParty');
    };

    return {
      joinQueue,
      leaveQueue,
      toggleQueue,
      user,
      isInQueue,
      loading,
      socket,
      partyMembers,
      isInParty,
      showAddToPartyModal,
      openAddToPartyModal: () => (showAddToPartyModal.value = true),
      closeAddToPartyModal: () => (showAddToPartyModal.value = false),
      leaveParty
    };
  },
};
</script>

<style scoped>
/* Your styles here */
</style>
