// store.js

import { createStore } from 'vuex';
import api from '@/api';

const store = createStore({
  state: {
    isInQueue: false,
    user: {
      avatar: "",
      username: "",
      elo: "",
      accepted: false,
      id: null,
      continent: ""
    },
    teammates: [null, null, null, null, null],
    friends: [],
    searchingForMatch: false,
    matchFound: false,
    teammatesAccepted: 0,
    isLoggedIn: false,
    formatedElo: "",
    formatedEloColor: "gray",
    queueCount: 0,
    partyInvitations: [],
    partyMembers: [],
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TEAMMATES(state, teammates) {
      state.teammates = teammates;
    },
    SET_FRIENDS(state, friends) {
      state.friends = friends;
    },
    SET_SEARCHING_FOR_MATCH(state, searching) {
      state.searchingForMatch = searching;
    },
    SET_QUEUE_COUNT(state, count) {
      state.queueCount = count;
    },
    INCREMENT_TEAMMATES_ACCEPTED(state) {
      state.teammatesAccepted += 1;
      state.user.accepted = true;
    },
    SET_PARTY_MEMBERS(state, partyMembers) {
      state.partyMembers = partyMembers;
    },
    CLEAR_PARTY_MEMBERS(state) {
      state.partyMembers = [null, null, null, null];
    },
    SET_IN_QUEUE(state, status) {
      state.isInQueue = status;
    },
  },
  actions: {
     async checkLoginStatus({ commit, dispatch }) {
      try {
        const response = await api.checkLoginStatus();
        if (response.user) {
          commit('SET_USER', response.user);

          // Fetch additional data
          await dispatch('fetchPlayerData');
          await dispatch('fetchElo');
          await dispatch('fetchQueueCount');
          await dispatch('fetchFriends');
          await dispatch('checkIfInParty');
        } else {
          console.warn('No user found in login status response');
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    },
    async fetchPlayerData({ state, commit }) {
      try {
        const data = await api.getPlayerSummaries(state.user.id);
        commit('SET_USER', {
          ...state.user,
          avatar: data.avatar,
          username: data.displayName,
          continent: data.continent
        });
      } catch (error) {
        console.error("Error fetching player data:", error);
      }
    },
    async fetchElo({ state, commit }) {
      try {
        const csgoStatsArray = await api.getCsgoStats(state.user.id);
        const rankId = csgoStatsArray[2].rank_id;
        commit('SET_USER', {
          ...state.user,
          elo: rankId,
          formatedElo: new Intl.NumberFormat().format(rankId)
        });
      } catch (error) {
        console.error("Error fetching ELO:", error);
      }
    },
    async fetchFriends({ state, commit }) {
      try {
        const friends = await api.getFriends(state.user.id);
        commit('SET_FRIENDS', friends);
      } catch (error) {
        console.error("Error fetching friends list:", error);
      }
    },
    async checkIfInParty({ commit }) {
      try {
        const response = await api.checkIfInParty();
        const userResponse = await api.checkLoginStatus();

        if (response && response.ownerId) {
          const members = [response.ownerId, ...response.members];
          const partyInfoPromises = members.map(memberId => api.getPlayerSummaries(memberId));
          const partyInfo = await Promise.all(partyInfoPromises);

          let party = partyInfo
            .map(member => ({
              id: member.steamId,
              displayName: member.displayName,
              avatar: member.avatar,
              isOwner: response.ownerId
            }))
            .filter(member => member.id !== userResponse.user.id);

          while (party.length < 4) {
            party.push(null);
          }

          commit('SET_PARTY_MEMBERS', party);
        } else {
          commit('CLEAR_PARTY_MEMBERS');
        }
      } catch (error) {
        console.error("Error checking if in party:", error);
      }
    },
    clearPartyMembers({ commit }) {
      commit('SET_PARTY_MEMBERS', [null, null, null, null]);
    },
    async toggleSearch({ state, commit }) {
      try {
        const endpoint = state.searchingForMatch ? '/api/leave-queue' : '/api/enter-queue';
        const response = await fetch(endpoint, {
          method: 'POST',
          credentials: 'include',
        });
        const data = await response.json();
        commit('SET_QUEUE_COUNT', data.queueCount);
        commit('SET_SEARCHING_FOR_MATCH', !state.searchingForMatch);
      } catch (error) {
        console.error(`Error ${state.searchingForMatch ? 'leaving' : 'joining'} the queue:`, error);
      }
    },
    acceptMatch({ commit }) {
      commit('INCREMENT_TEAMMATES_ACCEPTED');
    },
    addTeammate({ commit }, friend) {
      commit('ADD_TEAMMATE', friend);
    },
    async acceptPartyInvitation({ dispatch }, invitationId) {
      try {
        await api.acceptPartyInvitation(invitationId);
        await dispatch('checkIfInParty');
      } catch (error) {
        console.error("Error accepting party invitation:", error);
      }
    },
    async removePartyMember({ dispatch }, memberId) {
      try {
        await api.removePartyMember(memberId);
        await dispatch('checkIfInParty');
      } catch (error) {
        console.error("Error removing party member:", error);
      }
    },
    async leaveParty({ commit, dispatch }) {
      try {
        commit('CLEAR_PARTY_MEMBERS');
        await api.leaveParty();
        await dispatch('checkIfInParty');
      } catch (error) {
        console.error('Error leaving party:', error);
      }
    },
    updateQueueStatus({ commit }, status) {
      commit('SET_IN_QUEUE', status);
    },
    async checkQueueStatus({ commit }) {
      const response = await api.checkQueueStatus(); // Assuming this API exists
      commit('SET_IN_QUEUE', response.inQueue);
      console.log(response.inQueue);
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    user: state => state.user,
    teammates: state => state.teammates,
    friends: state => state.friends,
    searchingForMatch: state => state.searchingForMatch,
    queueCount: state => state.queueCount,
    formatedElo: state => state.formatedElo,
    partyInvitations: state => state.partyInvitations,
    partyMembers: state => state.partyMembers,
    isInQueue: (state) => state.isInQueue,
  },
});

export default store;
