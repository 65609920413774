<template>
    <div>
        <div v-if="loading == true" class="flex center h-70">
            <div class="spinner"></div>
        </div>
        <div v-if="loading == false">
            <div class="sticky-header">
                <div>
                    <ul class="page-tab-menu flex">
                        <li :class="{ 'tab-active': selectedTab === 'Global' }" @click="selectTab('Global')">Global</li>
                        <li :class="{ 'tab-active': selectedTab === 'Oceania' }" @click="selectTab('Oceania')">Oceania
                        </li>
                        <li :class="{ 'tab-active': selectedTab === 'North America' }"
                            @click="selectTab('North America')">
                            North America
                        </li>
                        <li :class="{ 'tab-active': selectedTab === 'South America' }"
                            @click="selectTab('South America')">
                            South America
                        </li>
                        <li :class="{ 'tab-active': selectedTab === 'Europe' }" @click="selectTab('Europe')">Europe</li>
                        <li :class="{ 'tab-active': selectedTab === 'Asia' }" @click="selectTab('Asia')">Asia</li>
                        <li :class="{ 'tab-active': selectedTab === 'Africa' }" @click="selectTab('Africa')">Africa</li>
                    </ul>
                </div>
                <div class="border-bottom profile-detailed flex"
                    :style="{ backgroundImage: `url(${defaultProfile.background})` }">
                    <div class="profile-detailed-overlay"></div>
                    <ul class="flex column">

                    </ul>
                </div>
            </div>
            <div class="profile-stats flex column">
                <div class="page-tabs container">
                    <div v-if="selectedTab === 'Global'" class="flex column">
                        <ul class="flex table column ladder-table">
                            <div class="table-headings flex">
                                <div>
                                    <div class="table-heading">Place</div>
                                </div>
                                <div>
                                    <div class="table-heading">Name</div>
                                </div>
                                <div>
                                    <div class="table-heading">W</div>
                                </div>
                                <div>
                                    <div class="table-heading">L</div>
                                </div>
                                <div>
                                    <div class="table-heading">W/L %</div>
                                </div>
                                <div>
                                    <div class="table-heading">Points</div>
                                </div>
                                <div>
                                    <div class="table-heading">Region</div>
                                </div>
                            </div>
                            <div class="table-content flex" v-for="(user, index) in users" :key="user.id">
                                <div>{{ index + 1 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ? user.wins : 0 }}</div>
                                <div>{{ user.losses ? user.losses : 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ? user.gamecoins : 0 }}</div>
                                <div>{{ user.region }}</div>
                            </div>
                        </ul>
                    </div>
                    <div v-if="selectedTab === 'Oceania'" class="flex column">
                        <ul class="flex table column ladder-table">
                            <div class="table-headings flex">
                                <div>
                                    <div class="table-heading">Place</div>
                                </div>
                                <div>
                                    <div class="table-heading">Name</div>
                                </div>
                                <div>
                                    <div class="table-heading">W</div>
                                </div>
                                <div>
                                    <div class="table-heading">L</div>
                                </div>
                                <div>
                                    <div class="table-heading">W/L %</div>
                                </div>
                                <div>
                                    <div class="table-heading">Points</div>
                                </div>
                            </div>
                            <div class="table-content flex" v-for="(user, index) in filteredUsers" :key="user.id">
                                <div>{{ index + 1 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ? user.wins : 0 }}</div>
                                <div>{{ user.losses ? user.losses : 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ? user.gamecoins : 0 }}</div>
                            </div>
                        </ul>
                    </div>
                    <div v-if="selectedTab === 'North America'" class="flex column">
                        <ul class="flex table column ladder-table">
                            <div class="table-headings flex">
                                <div>
                                    <div class="table-heading">Place</div>
                                </div>
                                <div>
                                    <div class="table-heading">Name</div>
                                </div>
                                <div>
                                    <div class="table-heading">W</div>
                                </div>
                                <div>
                                    <div class="table-heading">L</div>
                                </div>
                                <div>
                                    <div class="table-heading">W/L %</div>
                                </div>
                                <div>
                                    <div class="table-heading">Points</div>
                                </div>
                            </div>
                            <div class="table-content flex" v-for="(user, index) in filteredUsers" :key="user.id">
                                <div>{{ index + 1 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ? user.wins : 0 }}</div>
                                <div>{{ user.losses ? user.losses : 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ? user.gamecoins : 0 }}</div>
                            </div>
                        </ul>
                    </div>
                    <div v-if="selectedTab === 'South America'" class="flex column">
                        <ul class="flex table column ladder-table">
                            <div class="table-headings flex">
                                <div>
                                    <div class="table-heading">Place</div>
                                </div>
                                <div>
                                    <div class="table-heading">Name</div>
                                </div>
                                <div>
                                    <div class="table-heading">W</div>
                                </div>
                                <div>
                                    <div class="table-heading">L</div>
                                </div>
                                <div>
                                    <div class="table-heading">W/L %</div>
                                </div>
                                <div>
                                    <div class="table-heading">Points</div>
                                </div>
                            </div>
                            <div class="table-content flex" v-for="(user, index) in filteredUsers" :key="user.id">
                                <div>{{ index + 1 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ? user.wins : 0 }}</div>
                                <div>{{ user.losses ? user.losses : 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ? user.gamecoins : 0 }}</div>
                            </div>
                        </ul>
                    </div>
                    <div v-if="selectedTab === 'Europe'" class="flex column">
                        <ul class="flex table column ladder-table">
                            <div class="table-headings flex">
                                <div>
                                    <div class="table-heading">Place</div>
                                </div>
                                <div>
                                    <div class="table-heading">Name</div>
                                </div>
                                <div>
                                    <div class="table-heading">W</div>
                                </div>
                                <div>
                                    <div class="table-heading">L</div>
                                </div>
                                <div>
                                    <div class="table-heading">W/L %</div>
                                </div>
                                <div>
                                    <div class="table-heading">Points</div>
                                </div>
                            </div>
                            <div class="table-content flex" v-for="(user, index) in filteredUsers" :key="user.id">
                                <div>{{ index + 1 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ? user.wins : 0 }}</div>
                                <div>{{ user.losses ? user.losses : 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ? user.gamecoins : 0 }}</div>
                            </div>
                        </ul>
                    </div>
                    <div v-if="selectedTab === 'Asia'" class="flex column">
                        <ul class="flex table column ladder-table">
                            <div class="table-headings flex">
                                <div>
                                    <div class="table-heading">Place</div>
                                </div>
                                <div>
                                    <div class="table-heading">Name</div>
                                </div>
                                <div>
                                    <div class="table-heading">W</div>
                                </div>
                                <div>
                                    <div class="table-heading">L</div>
                                </div>
                                <div>
                                    <div class="table-heading">W/L %</div>
                                </div>
                                <div>
                                    <div class="table-heading">Points</div>
                                </div>
                            </div>
                            <div class="table-content flex" v-for="(user, index) in filteredUsers" :key="user.id">
                                <div>{{ index + 1 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ? user.wins : 0 }}</div>
                                <div>{{ user.losses ? user.losses : 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ? user.gamecoins : 0 }}</div>
                            </div>
                        </ul>
                    </div>
                    <div v-if="selectedTab === 'Africa'" class="flex column">
                        <ul class="flex table column ladder-table">
                            <div class="table-headings flex">
                                <div>
                                    <div class="table-heading">Place</div>
                                </div>
                                <div>
                                    <div class="table-heading">Name</div>
                                </div>
                                <div>
                                    <div class="table-heading">W</div>
                                </div>
                                <div>
                                    <div class="table-heading">L</div>
                                </div>
                                <div>
                                    <div class="table-heading">W/L %</div>
                                </div>
                                <div>
                                    <div class="table-heading">Points</div>
                                </div>
                            </div>
                            <div class="table-content flex" v-for="(user, index) in filteredUsers" :key="user.id">
                                <div>{{ index + 1 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ? user.wins : 0 }}</div>
                                <div>{{ user.losses ? user.losses : 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ? user.gamecoins : 0 }}</div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "../api";

export default {
    name: "UserLeaderboard",
    data() {
        return {
            selectedTab: "Global",
            defaultProfile: { background: require('@/assets/svg/profiles/default/background.svg') },
            users: [],
            loading: true,
        }
    },
    methods: {
        selectTab(tab) {
            this.selectedTab = tab;
        },
        async fetchData() {
            try {
                this.loading = true;
                this.users = await api.getAllUsers();
                this.loading = false;
            } catch (error) {
                console.log("Error fetching users: " + error);
            }
        }
    },
    computed: {
        filteredUsers() {
            return this.users.filter((user) => user.region === this.selectedTab).sort((a, b) => b.gamecoins - a.gamecoins);
        },
    },
    async mounted() {
        await this.fetchData();
    },
}
</script>

<style scoped>
.page-tabs {
    margin-top: 25vh;
}
</style>