import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles.css';
import store from '@/store';  // Adjust the import path as needed

createApp(App)
  .use(router)
  .use(store)
  .mount('#app');
