import { createRouter, createWebHistory } from 'vue-router';
import MatchFinder from './components/MatchFinder.vue';
import ProfilePage from './components/ProfilePage.vue';
import GameLadders from './components/Ladders.vue';
import Tournaments from './components/Tournaments.vue';
import Team from './components/Team.vue';
import MatchDetails from './components/Match.vue';
import UserLeaderboard from './components/Leaderboard.vue';
import Wagers from './components/Wagers.vue';
import RoadMap from './components/roadmap.vue';
import PrivacyPolicy from './components/privacy.vue';
import Terms from './components/terms.vue';
import api from '@/api'; // Import the api.js module

const routes = [
  { path: '/', name: 'Match Finder', component: MatchFinder },
  { path: '/profile/:steamid', name: 'Profile', component: ProfilePage }, // Define a dynamic route parameter for the SteamID
  { path: '/ladders', name: 'GameLadders', component: GameLadders },
  { path: '/tournaments', name: 'Tournaments', component: Tournaments },
  { path: '/team/:teamid', name: 'TeamDetails', component: Team },
  { path: '/match/:challenge', name: 'MatchDetails', component: MatchDetails },
  { path: '/leaderboards', name: 'UserLeaderboard', component: UserLeaderboard },
  { path: '/wagers', name: 'WagerMatches', component: Wagers },
  { path: '/roadmap', name: 'RoadMap', component: RoadMap },
  { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/terms-of-service', name: 'TermOfServ', component: Terms }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});



// Add navigation guard
router.beforeEach(async (to, from, next) => {

  const publicPages = ['PrivacyPolicy', 'TermOfServ'];

  // Check if the current route is one of the public pages
  if (publicPages.includes(to.name)) {
    next(); // Allow access without checking login status
    return;
  }

  const response = await api.checkLoginStatus();
  const isLoggedIn = response.isLoggedIn;

  if (isLoggedIn) {
    // If logged in and trying to access a protected route, allow it
    if (to.name !== 'RoadMap') {
      next();
    } else {
      // If logged in but navigating to /roadmap, redirect to matchfinder
      next({ name: 'Match Finder' });
    }
  } else {
    // If not logged in, redirect to the roadmap regardless of route
    if (to.name !== 'RoadMap') {
      next({ name: 'RoadMap' });
    } else {
      next();
    }
  }
});

export default router;
