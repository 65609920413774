<template>
  <div v-if="showModal" class="modal-overlay">
    <transition name="modal">
      <div>
        <div v-if="loading" class="flex center h-70">
          <div class="spinner"></div>
        </div>
        <div class="modal modal-popup show" v-if="!loading">
          <h3 class="heavy">Invite Friend</h3>
          <ul class="flex column">
            <li v-for="friend in friends" :key="friend.steamId" class="friend-item chat-friend">
              <div class="friend-info flex">
                <img :src="friend.avatar" alt="avatar" class="chat-friend-avatar">
                <span>{{ friend.displayName }}</span>
                <button
                  v-if="invitationStatus[friend.friendId] !== 'sent'"
                  class="invite-btn flex"
                  @click="sendPartyInvitation(friend)"
                >
                  Invite
                </button>
              </div>
              <div v-if="invitationStatus[friend.friendId] === 'sent'" class="invited-overlay">
                Invited
              </div>
            </li>
          </ul>
          <button @click="closeModal">Close</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, onMounted, ref, watch, toRefs } from 'vue';
import api from '@/api';
import { getSocket } from '@/socket';

export default {
  props: {
    showModal: Boolean
  },
  setup(props, { emit }) {
    const socket = ref(null);
    const invitationStatus = reactive({});
    const loading = ref(true);
    const friends = ref([]);

    const sendPartyInvitation = async (friend) => {
      try {
        const user = await api.checkLoginStatus();
        const data = {
          friendId: friend.friendId,
          userId: user.user.id
        };
        socket.value.emit('sendPartyInvitation', data);
        invitationStatus[friend.friendId] = 'sent';
      } catch (error) {
        console.error('Error sending party invitation:', error);
      }
    };

    const fetchFriendsAndInvitations = async () => {
      try {
        const partyInvites = await api.getSentPartyInvitations();
        friends.value.forEach(friend => {
          if (partyInvites.some(invite => invite.inviteeId === friend.friendId)) {
            invitationStatus[friend.friendId] = 'sent';
          } else {
            invitationStatus[friend.friendId] = 'not_sent';
          }
        });
        loading.value = false;
      } catch (error) {
        console.error('Error fetching party invitations:', error);
      }
    };

    const fetchFriends = async () => {
      try {
        const friendsList = await api.getFriends();
        friends.value = friendsList;
        friendsList.forEach(friend => {
          invitationStatus[friend.friendId] = 'not_sent';
        });
        await fetchFriendsAndInvitations();
      } catch (error) {
        console.error('Error fetching friends list:', error);
      }
    };

    const closeModal = () => {
      emit('close');
    };

    onMounted(async () => {
      try {
        await fetchFriends();
        socket.value = getSocket();
      } catch (error) {
        console.log(error);
      }
    });

    watch(
      () => props.showModal,
      (newVal) => {
        if (newVal) {
          fetchFriends();
        }
      }
    );

    return {
      ...toRefs(props),
      sendPartyInvitation,
      invitationStatus,
      loading,
      friends,
      closeModal
    };
  }
};
</script>

<style scoped>
.modal {
  margin-left: 25vh;
  overflow: auto;
  align-content: center;
  justify-content: center;
}
</style>
