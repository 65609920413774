<template>
    <div class="solo-page">
        <a href="https://playerverse.gg">
            <img src="../assets/svg/logo_long.svg" />
        </a>
        <h3>PlayerVerse.gg Privacy Policy</h3>
        <p>We respect your privacy and want you to feel confident about how we handle your data.</p>

        <ul>
            <li>
                <h4>Data Collection</h4>PlayerVerse.gg does not collect personal information such as emails, usernames,
                or passwords for account
                creation or login purposes. This ensures we prioritise your privacy and minimise the information we
                handle.
            </li>
            <li>
                <h4>Cookies</h4>
                PlayerVerse.gg may use cookies to enhance your browsing experience, such as maintaining your session
                when using
                the platform. These cookies do not store personal data and can be cleared from your browser settings.
            </li>

            <li>
                <h4>Third-Party Services</h4>
                Any third-party services used for functionality (such as external social media integrations or payment
                systems)
                are governed by the respective privacy policies of those services. We do not share or sell any user data
                with
                third parties.
            </li>

            <li>
                <h4>Security</h4>
                While we strive to protect your data, no online service is entirely secure. Use of PlayerVerse.gg is at
                your own
                risk, and we recommend following best security practices when using online platforms.
            </li>

            <li>
                <h4>Updates to the Privacy Policy</h4>
                As PlayerVerse.gg evolves, our privacy practices may change. Updates to the Privacy Policy will be
                communicated
                through the platform.
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>