<template>
    <div class="solo-page">
        <a href="https://playerverse.gg">
            <img src="../assets/svg/logo_long.svg" />
        </a>
        <h3>Terms of Service
            Welcome to PlayerVerse.gg!</h3>
        <p>Please read these terms carefully before using our platform. By accessing or using PlayerVerse.gg, you agree
            to be bound by these Terms of Service.</p>

        <ul>
            <li>
                <h4>General Use</h4>PlayerVerse.gg is a grassroots competitive gaming platform primarily focused on CS2.
                We are currently in Alpha, and as such, features and functionality may be subject to change, addition,
                or removal without notice. Your patience and feedback are appreciated as we work to improve the
                platform.
            </li>
            <li>
                <h4>Content Ownership</h4>
                All user-generated content, including match results, stats, and other contributions, remain the property
                of the user. By using PlayerVerse.gg, you grant us permission to display and use this content on our
                platform.
            </li>

            <li>
                <h4>Code of Conduct</h4>
                PlayerVerse.gg is a community for gamers. We expect all users to maintain respect, sportsmanship, and
                positive interaction. Hate speech, harassment, or cheating will result in account suspension or banning.
            </li>

            <li>
                <h4>Limitation of Liability</h4>
                PlayerVerse.gg is provided "as is" during our Alpha phase. We do not guarantee uninterrupted service or
                the accuracy of the content on the platform. PlayerVerse.gg is not liable for any loss or damage
                resulting from the use of our platform.
            </li>

            <li>
                <h4>Changes to Terms</h4>
                We reserve the right to update these Terms of Service at any time. By continuing to use PlayerVerse.gg,
                you agree to the revised terms.
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'TermOfServ'
}
</script>