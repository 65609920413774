<template>
    <div>
        <div class="sticky-header">
            <div>
                <ul class="page-tab-menu flex">
                    <li v-for="region in regions" :key="region" :class="{ 'tab-active': selectedTab === region }"
                        @click="selectTab(region)">
                        {{ region }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="loadingDiv flex center h-70" v-if="loading">
            <div class="spinner"></div>
        </div>
        <div class="flex column" v-if="!loading">
            <div class="container wager-container">
                <h3>Tournaments</h3>
                <div v-for="region in regions" :key="region">
                    <div v-if="selectedTab === region" class="flex column">
                        <ul class="flex">
                            <li v-if="filteredTourns.length === 0" class="no-item-message">
                                No Tournaments
                            </li>
                            <li v-else class="display-card flex column" :style="{ backgroundImage: `url(${tourn.hero})` }" v-for="tourn in filteredTourns" :key="tourn.id">
                                <div class="display-card-header flex">
                                    <h4>{{ tourn.title }}</h4>
                                </div>
                                <div class="display-card-body flex">
                                    <p>Prize: ${{ tourn.prizepool }}</p>
                                </div>
                                <div class="display-card-body flex">
                                    <p>{{ formatDateTime(tourn.date) }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import api from "../api";

export default {
    name: "MUGG Tournaments",
    setup() {
        const selectedTab = ref("Oceania");
        const regions = ref(["Oceania", "North America", "South America", "Europe", "Asia", "Africa"]);
        const loading = ref(true);
        const tournArray = ref([]);
        const logged = ref({});

        const selectTab = (region) => {
            selectedTab.value = region;
        };

        const fetchData = async () => {
            try {
                loading.value = true;

                const [tourns, loggedInUser] = await Promise.all([
                    api.getTourns(),
                    api.checkLoginStatus()
                ]);

                const loggedInUserSummary = await api.getPlayerSummaries(loggedInUser.user.id);

                logged.value = loggedInUser.user;
                logged.value.region = loggedInUserSummary.region;

                // Assign tournaments to the array
                tournArray.value = tourns;

                loading.value = false;
            } catch (error) {
                console.error("Error fetching data:", error);
                loading.value = false;
            }
        };

        // Computed property to filter tournaments by selected tab
        const filteredTourns = computed(() => {
            return tournArray.value.filter(tourn => tourn.region === selectedTab.value);
        });

        // Method to format the date
        const formatDateTime = (date) => {
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true // Use 12-hour format
            };

            // Get the formatted date and time
            let formattedDate = new Intl.DateTimeFormat(navigator.language, options).format(new Date(date));

            // Handle cases where 'at' might appear
            formattedDate = formattedDate.replace(' at ', ' || ');

            // Customize the 12:00 AM output by replacing it with "|| 12am"
            formattedDate = formattedDate.replace(/12:00 AM|00:00/, '12am');
            formattedDate = formattedDate.replace(/12:00 PM/, '12pm');

            return formattedDate;
        };


        onMounted(fetchData);

        return {
            regions,
            selectTab,
            loading,
            selectedTab,
            filteredTourns,
            formatDateTime
        };
    }
};
</script>
