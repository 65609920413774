<template>
  <div>
    <p v-if="isMobile">
      PlayerVerse.GG is currently in Alpha and is not supported on Mobile.
    </p>
    <div v-else>
      <SiteMenu v-if="isLoggedIn" class="siteMenu"></SiteMenu>
      <router-view :class="[isLoggedIn ? 'router-container z30' : '']" v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import SiteMenu from './components/SiteMenu.vue'
import { mapActions } from 'vuex';
import api from './api'; // Import the api.js module.
import { ref, onMounted } from 'vue';

export default {
  name: 'PlayerVerse.gg | The Ultimate Competitive Gaming Platform',
  setup() {
    const isMobile = ref(false);
    const isLoggedIn = ref(false);

    // Function to detect mobile device based on user agent
    const detectMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      isMobile.value = /android|iPad|iPhone|iPod|Windows Phone/i.test(userAgent);
    };

    // Call the API to check login status
    const checkLoginStatus = async () => {
      const response = await api.checkLoginStatus();
      isLoggedIn.value = response.isLoggedIn; // Update login state
    };

    // Run the detection and login check on mount
    onMounted(() => {
      detectMobile();
      checkLoginStatus();
    });

    return {
      isMobile,
      isLoggedIn
    };
  },
  components: {
    SiteMenu
  },
  methods: {
    ...mapActions(['checkLoginStatus']), // Assuming you still need to map Vuex actions
  }
}
</script>

<style>

</style>