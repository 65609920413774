import api from "@/api";

// Server locations to ping (as per Dathost documentation)
const serverLocations = [
  { dns: 'beauharnois.dathost.net', city: 'Toronto', apiId: 'beauharnois', region: 'North America' },
  { dns: 'new-york-city.dathost.net', city: 'New York', apiId: 'new_york_city', region: 'North America' },
  { dns: 'los-angeles.dathost.net', city: 'Los Angeles', apiId: 'los_angeles', region: 'North America' },
  { dns: 'miami.dathost.net', city: 'Miami', apiId: 'miami', region: 'North America' },
  { dns: 'chicago.dathost.net', city: 'Chicago', apiId: 'chicago', region: 'North America' },
  { dns: 'portland.dathost.net', city: 'Seattle', apiId: 'portland', region: 'North America' },
  { dns: 'dallas.dathost.net', city: 'Dallas', apiId: 'dallas', region: 'North America' },
  { dns: 'atlanta.dathost.net', city: 'Atlanta', apiId: 'atlanta', region: 'North America' },
  { dns: 'denver.dathost.net', city: 'Denver', apiId: 'denver', region: 'North America' },
  { dns: 'copenhagen.dathost.net', city: 'Copenhagen', apiId: 'copenhagen', region: 'Europe' },
  { dns: 'helsinki.dathost.net', city: 'Helsinki', apiId: 'helsinki', region: 'Europe' },
  { dns: 'strasbourg.dathost.net', city: 'Paris', apiId: 'strasbourg', region: 'Europe' },
  { dns: 'dusseldorf.dathost.net', city: 'Frankfurt', apiId: 'dusseldorf', region: 'Europe' },
  { dns: 'amsterdam.dathost.net', city: 'Amsterdam', apiId: 'amsterdam', region: 'Europe' },
  { dns: 'warsaw.dathost.net', city: 'Warsaw', apiId: 'warsaw', region: 'Europe' },
  { dns: 'barcelona.dathost.net', city: 'Madrid', apiId: 'barcelona', region: 'Europe' },
  { dns: 'stockholm.dathost.net', city: 'Stockholm', apiId: 'stockholm', region: 'Europe' },
  { dns: 'istanbul.dathost.net', city: 'Istanbul', apiId: 'istanbul', region: 'Europe' },
  { dns: 'bristol.dathost.net', city: 'London', apiId: 'bristol', region: 'Europe' },
  { dns: 'sydney.dathost.net', city: 'Sydney', apiId: 'sydney', region: 'Oceania' },
  { dns: 'sao-paulo.dathost.net', city: 'São Paulo', apiId: 'sao_paulo', region: 'South America' },
  { dns: 'santiago.dathost.net', city: 'Santiago', apiId: 'santiago', region: 'South America' },
  { dns: 'hong-kong.dathost.net', city: 'Hong Kong', apiId: 'hong_kong', region: 'Asia' },
  { dns: 'mumbai.dathost.net', city: 'Mumbai', apiId: 'mumbai', region: 'Asia' },
  { dns: 'tokyo.dathost.net', city: 'Tokyo', apiId: 'tokyo', region: 'Asia' },
  { dns: 'singapore.dathost.net', city: 'Singapore', apiId: 'singapore', region: 'Asia' },
  { dns: 'johannesburg.dathost.net', city: 'Johannesburg', apiId: 'johannesburg', region: 'Africa' },
  { dns: 'seoul.dathost.net', city: 'Seoul', apiId: 'seoul', region: 'Asia' }
];

// Function to perform the ping and return the median ping result
const doPing = (location, ws, pingResults = []) => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    ws.onmessage = (message) => {
      const decodedMessage = JSON.parse(message.data);
      if (decodedMessage.cmd === 'datPong') {
        pingResults.push(Date.now() - startTime);
        if (pingResults.length < 5) {
          doPing(location, ws, pingResults).then(resolve).catch(reject);
        } else {
          pingResults.sort((a, b) => a - b);
          resolve({ city: location.city, ping: pingResults[2], apiId: location.apiId, region: location.region });
          ws.close();
        }
      }
    };
    ws.onerror = (err) => {
      reject(err);
    };
    ws.onclose = () => {
      if (pingResults.length === 0) {
        reject(new Error(`No ping response from ${location.city}`));
      }
    };
    ws.send(JSON.stringify({ cmd: 'datPing' }));
  });
};

// Ping service for client-side execution
const pingService = async (playerId) => {
  const pingPromises = serverLocations.map(location => {
    return new Promise((resolve, reject) => {
      const ws = new WebSocket(`wss://${location.dns}/console-server/`);
      ws.onopen = () => {
        doPing(location, ws).then(resolve).catch(reject);
      };
      ws.onerror = reject;
    });
  });

  const pingResults = await Promise.allSettled(pingPromises);

  // Filter out failed pings
  const successfulPings = pingResults.filter(result => result.status === 'fulfilled').map(result => result.value);

  // Handle no successful pings
  if (successfulPings.length === 0) {
    throw new Error('No successful pings');
  }

  // Sort by ping to find the closest region
  successfulPings.sort((a, b) => a.ping - b.ping);
  const closestRegion = successfulPings[0];

  // Send the ping results to the server
  try {
    await api.SendPingResults({
      playerId,
      closestCity: closestRegion.city,
      closestApiId: closestRegion.apiId,
      region: closestRegion.region,
      pingResults: successfulPings
    });
    console.log(`Ping results for player ${playerId} sent successfully.`);
  } catch (error) {
    console.error('Error sending ping results:', error);
  }

  return closestRegion.city; // Return the closest city for potential use
};

export default pingService;
