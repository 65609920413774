<template>
  <div class="modal-overlay" v-if="isVisible">
    <div class="modal modal-popup show column flex">
      <h3 style="margin-bottom: 1em">Set Prize Pool</h3>
      <div class="modal-content-fill">
        <div>
          <input class="link-item" id="prize-pool" v-model="prizePoolAmount" type="number" min="0"
            placeholder="Enter amount" />
        </div>
      </div>
      <div class="flex center edit-controls">
        <button @click="submitPrizePool">Set Prize Pool</button>
        <button @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";

export default {
  name: "CreateWager",
  props: {
    isVisible: Boolean
  },
  data() {
    return {
      prizePoolAmount: 0 // Default value for the prize pool
    };
  },
  methods: {
    async createWagerMatch() {
      const userGameCoins = await api.getUserGameCoins(this.userId); // API call to check coins
      const buyIn = this.prizePool / 10;

      if (userGameCoins < buyIn) {
        this.$notify("You do not have enough game coins.");
        return;
      }

      // Proceed with match creation
      await api.createWagerMatch({
        prizePool: this.prizePool,
        buyIn: buyIn,
        players: this.players,
      });
    },
    closeModal() {
      this.$emit('close');
    },
    submitPrizePool() {
      if (this.prizePoolAmount >= 0) {
        this.$emit('submit', this.prizePoolAmount);
        this.closeModal();
      } else {
        alert('Please enter a valid amount.');
      }
    }
  }
};
</script>

<style scoped>
.modal {
  margin-left: 25vh;
  overflow: auto;
  align-content: center;
  justify-content: center;
}
</style>